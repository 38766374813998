/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AibotChannel } from '../models/AibotChannel';
import type { AibotMessage } from '../models/AibotMessage';
import type { ChartConfig } from '../models/ChartConfig';
import type { LlamaResponse } from '../models/LlamaResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AibotService {
    /**
     * Ask question
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns LlamaResponse LlamaResponse
     * @throws ApiError
     */
    public static postAibotInit(
        orgId: string,
        authorization: string,
        requestBody?: {
            tz?: string;
            channel: string;
        },
    ): CancelablePromise<LlamaResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/aibot/init',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Ask question
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns LlamaResponse LlamaResponse
     * @throws ApiError
     */
    public static postAibotProcess(
        orgId: string,
        authorization: string,
        requestBody?: {
            question: string;
            tz?: string;
            channel: string;
        },
    ): CancelablePromise<LlamaResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/aibot/process',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get chart sql
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId ID of the group
     * @param requestBody
     * @returns ChartConfig ChartConfig
     * @throws ApiError
     */
    public static postAibotSql(
        orgId: string,
        authorization: string,
        subOrgId?: string,
        requestBody?: {
            question: string;
            tz?: string;
        },
    ): CancelablePromise<ChartConfig> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/aibot/sql',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Ask question
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any LlamaResponse
     * @throws ApiError
     */
    public static postAibotChart(
        orgId: string,
        authorization: string,
        requestBody?: ChartConfig,
    ): CancelablePromise<{
        chart: Record<string, any>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/aibot/chart',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add reaction
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns LlamaResponse LlamaResponse
     * @throws ApiError
     */
    public static postAibotReaction(
        orgId: string,
        authorization: string,
        requestBody?: {
            channel: string;
            uuid: string;
            reaction: string;
        },
    ): CancelablePromise<LlamaResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/aibot/reaction',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Summarize rollout
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns LlamaResponse LlamaResponse
     * @throws ApiError
     */
    public static postAibotSummarizeRollout(
        orgId: string,
        authorization: string,
        requestBody?: {
            latest_data: Record<string, any>;
            timeline_data: Record<string, any>;
        },
    ): CancelablePromise<LlamaResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/aibot/summarize-rollout',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Analyze issue
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns LlamaResponse LlamaResponse
     * @throws ApiError
     */
    public static postAibotAnalyzeIssue(
        orgId: string,
        authorization: string,
        requestBody?: {
            issue_hash: string;
        },
    ): CancelablePromise<LlamaResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/aibot/analyze-issue',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List channels
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param limit The maximum number of channels to return in the response.
     * @param startKey The key from where to start fetching the next set of channels. Used for pagination.
     * @returns any Channels list
     * @throws ApiError
     */
    public static getAibotChannels(
        orgId: string,
        authorization: string,
        limit: number = 10,
        startKey?: string,
    ): CancelablePromise<{
        channels: Array<AibotChannel>;
        /**
         * The key to start the next page of results. Null if there are no more results.
         */
        next_start_key?: string;
        total_count: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/aibot/channels',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'limit': limit,
                'start_key': startKey,
            },
        });
    }
    /**
     * List messages
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param channel
     * @returns AibotMessage Bot response
     * @throws ApiError
     */
    public static getAibotMessages(
        orgId: string,
        authorization: string,
        channel?: string,
    ): CancelablePromise<Array<AibotMessage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/aibot/messages',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'channel': channel,
            },
        });
    }
}
